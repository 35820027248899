;(function ( $, _, window, document, undefined ) {

  var pluginName = 'globalErrors',
  defaults = {};

  function Plugin(element, options) {
    this.element = element;
    this.options = $.extend({}, defaults, options);
    this._defaults = defaults;
    this._name = pluginName;
    this.refs = {};
    this.init();
  }

  Plugin.prototype = {

    init: function() {

      this.refs.alert = $( this.element );
      this.refs.list = this.refs.alert.find('ul').first();

      if( this.refs.alert.hasClass('hidden') ) {
        this.refs.alert.hide().removeClass('hidden');
      }

    },

    show: function( errors ) {

      if( $.isArray(errors) ) {
        var compiled = _.template(
          '<% _.forEach(errors, function(error) { %>' +
            '<li><%- error %></li>' +
          '<% }); %>'
        );

        errors = errors.map(function(error) {
          return error.replace(/<\/?li>/gi, '');
        });

        errors = compiled({errors: errors});
      }

      this.refs.list.html( errors );

      if( this.refs.alert.is(':hidden') ) {
        this.refs.alert.show(function() {
          $('html,body').stop().animate({scrollTop: this.refs.alert.offset().top}, 'fast');
        }.bind(this));
      } else {
        $('html,body').stop().animate({scrollTop: this.refs.alert.offset().top}, 'fast');
      }

    },

    hide: function() {
      if( this.refs.alert.is(':visible') ) {
        this.refs.alert.slideUp(function() {
          this.refs.list.empty();
        }.bind(this));
      }
    }

  };

  $.fn[pluginName] = function ( options ) {
    var args = arguments;

    if ( options === undefined || typeof options === 'object' ) {

      return this.each(function () {
        if (!$.data(this, 'plugin_' + pluginName)) {
          $.data(this, 'plugin_' + pluginName,
          new Plugin( this, options ));
        }
      });

    } else if (
      typeof options === 'string' &&
      options[0] !== '_' &&
      options !== 'init'
    ) {

      var returns;

      this.each(function () {
        var instance = $.data(this, 'plugin_' + pluginName);

        if (
          instance instanceof Plugin &&
          typeof instance[options] === 'function'
        ) {
          returns = instance[options].apply(
            instance, Array.prototype.slice.call( args, 1 )
          );
        }

        if (options === 'destroy') {
          $.data(this, 'plugin_' + pluginName, null);
        }
      });

      return returns !== undefined ? returns : this;
    }
  };

})( jQuery, _, window, document );
