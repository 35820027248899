;(function(window) {

  if(window.Parsley) {

    // ABA
    window.Parsley
      .addValidator('aba', function (value, requirement) {
        var isValid = true;

        if( requirement === true ) {

          // First, remove any non-numeric characters.
          value = value.replace(/[^0-9]/g, '');

          // Check the length, it should be nine digits.
          if (value.length !== 9) {
            isValid = false;
          } else {

            // Now run through each digit and calculate the total.
            var n = 0;

            for (var i = 0; i < value.length; i += 3) {
              n += parseInt(value.charAt(i), 10) * 3;
              n += parseInt(value.charAt(i + 1), 10) * 7;
              n += parseInt(value.charAt(i + 2), 10);
            }

            // If the resulting sum is an even multiple of ten (but not zero),
            // the aba routing number is good.

            isValid = n !== 0 && n % 10 === 0 ? true : false;

          }

        }

        return isValid;
      }, 32)
      .addMessage('en', 'aba', 'not a valid ABA routing number');

    // Bank Account Number
    window.Parsley
      .addValidator('bankaccount', function (value, requirement) {
        var isValid = true;

        if( requirement === true ) {
          // First, remove any unexpected characters.
          value = value.replace(/[^0-9\*]/g, '');

          isValid = value === '' || /^\d{4,17}$/.test(value);
        }

        return isValid;
      }, 32)
      .addMessage('en', 'bankaccount', 'must be a 4 to 17 digit number');

    // Currency
    window.Parsley
      .addValidator('currency', function (value, requirement) {
        var pattern = /^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{2})?$/;
        return requirement !== 1 || pattern.test(value);
      }, 32)
      .addMessage('en', 'currency', 'not an accepted currency format');

    // Currency, Max
    window.Parsley
      .addValidator('maxcurrency', function(value, requirement) {
        var isValid = true;
        value = parseFloat( value.toString().replace(/[^\d\.]/gi, '') );
        requirement = parseFloat( requirement.toString().replace(/[^\d\.]/gi, '') );

        if( ! isNaN(value) && ! isNaN(requirement) ) {
          isValid = value <= requirement;
        }

        return isValid;
      }, 32)
      .addMessage('en', 'maxcurrency', 'must be less than $%s');

    // Dates
    window.Parsley
      .addValidator('date', function (value, requirement) {
        var vernacular = /^(0?[1-9]||1[0-2])[\/\-](0?[1-9]||[12]\d||3[0-1])[\/\-](19||20)\d{2}$/;
        var iso = /^(19||20)\d{2}[\/\-](0?[1-9]||1[0-2])[\/\-](0?[1-9]||[12]\d||3[0-1])$/;
        return requirement !== 1 || vernacular.test(value) || iso.test(value);
      }, 32)
      .addMessage('en', 'date', 'not an accepted date');

    // DOB 16 or older
    window.Parsley
      .addValidator('agecheck', function(value, requirement) {
        var today = moment();
        var dob = moment(value);
        var agecheck = today.diff(dob, 'years') ;
        return requirement !== 1 || agecheck >= 16;
      })
      .addMessage('en', 'agecheck', 'not a valid age');

    // Greater than zero
    window.Parsley
      .addValidator('greaterthanzero', function (value, requirement) {
        return requirement !== 1 || (value && parseFloat(value) > 0.00);
      }, 32)
      .addMessage('en', 'greaterthanzero', 'must be greater than zero');

    // Numeric
    window.Parsley
      .addValidator('numeric', function (value, requirement) {
        return requirement !== 1 || !isNaN(parseFloat(value) && isFinite(value));
      }, 32)
      .addMessage('en', 'numeric', 'must be numeric');

    // Postal Codes
    window.Parsley
      .addValidator('postalcode', function (value, requirement) {
        var pattern = /^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/;
        if (value === '' || (value !== '' && pattern.test(value))) {
          return true;
        } else {
          return false;
        }
      }, 32)
      .addMessage('en', 'postalcode', 'not a valid postal code');

    // Secure Placeholder
    window.Parsley
      .addValidator('secureplaceholder', function (value, requirement) {
        return requirement === true && value === '' || value !== '';
      }, 32)
      .addMessage('en', 'secureplaceholder', 'required');

    // Selectize Async Lookups
    window.Parsley
      .addValidator('selectizecontroller', function(value, requirement) {
        return false;
      }, 32)
      .addMessage('en', 'selectizecontroller', 'cannot communicate with server');

    // Telephone Numbers
    window.Parsley
      .addValidator('telephone', function (value, requirement) {
        var pattern = /^(\+?1[ \-\.]?)?(\([2-9]([02-9]\d|1[02-9])\) ?|[2-9]([02-9]\d|1[02-9]))[\-\.]?[2-9]([02-9]\d|1[02-9])[\-\.]?\d{4}.*$/;
        if (value === '' || (value !== '' && pattern.test(value))) {
          return true;
        } else {
          return false;
        }
      }, 32)
      .addMessage('en', 'telephone', 'not a valid telephone number');

    // Strict URLs
    window.Parsley
      .addValidator('urlstrict', function (value, requirement) {
          var pattern = /^(https?|s):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

          if (value === '') {
            return true;
          } else if (value !== '' && pattern.test(value)) {
            return true;
          } else {
            return false;
          }
      }, 32)
      .addMessage('en', 'urlstrict', 'URL must include "http://"" or "https://"');

    // No whitespace
    window.Parsley
      .addValidator('nospaces', function (value, requirement) {
        var pattern = /^[^\s]+$/;
        if (value === '' || (value !== '' && pattern.test(value))) {
          return true;
        } else {
          return false;
        }
      }, 32)
      .addMessage('en', 'nospaces', 'must not contain spaces');

    // Password
    window.Parsley
      .addValidator('passwordstrength', function (value, requirement) {
        if (value === '' || (value !== '' && test_password(value))) {
          return true;
        } else {
          return false;
        }
      }, 32)
      .addMessage('en', 'passwordstrength', 'not strong enough');

    function test_password(password) {
      var passed = 0;
      var tests = [/[A-Z]+/, /[a-z]+/, /\d+/, /[^A-Za-z\d\s]+/];
      for (var i = 0; i < tests.length; i++) {
        if(tests[i].test(password)) {
          passed++
        }
      }
      return passed === tests.length;
    }

  }

})(window);
