;(function(window, $) {

  var detect = {
    android: isAndroid(),
    dateSuppored: isDateSupported()
  };

  if( ! detect.android ) {

    Inputmask.extendAliases({
      phoneAndExtension: {
        mask: '(999) 999-9999[ ][P9{1,5}]',
        greedy: false,
        definitions: {
          'P': {
            validator: function(chrs, buffer, pos, strict, opts) {
              var mask;
              if( /[ex]/i.test(chrs) ) {
                mask = { c: 'x' };
              } else if( /[,;#+]/i.test(chrs) ) {
                mask = { c: ',' };
              } else {
                mask = false;
              }
              return mask;
            },
            cardinality: 1
          }
        }
      }
    });

  }

  $(function() {

    if( ! detect.android ) {
      $('input[type="tel"]').inputmask('phoneAndExtension');
      $('input[data-parsley-currency]').inputmask('currency', {prefix: '', rightAlign: false, numericInput: true});
      $('input[data-inputmask]').inputmask();
    }

    if(! detect.dateSuppored ) {

      $('input[type="date"]').each(function() {
        var $self = $(this);
        var value = $.trim( $self.val() );
        var valueFormatted = isoToLocalUsDate( value );
        if( value !== valueFormatted) {
          $self.val(valueFormatted);
        }
        if( ! isAndroid ) {
          $self.inputmask('mm/dd/yyyy');
        }
      });
    }

  });

  function isoToLocalUsDate( date ) {
    if(date && /^\d{4}\-\d{2}\-\d{2}$/.test(date)) {
      date = date.replace(/(\d{4})\-(\d{2})\-(\d{2})/, '$2/$3/$1');
    }
    return date;
  }

  function isAndroid() {
    return navigator.userAgent.toLowerCase().indexOf('android') > -1;
  }

  function isDateSupported() {
    var invalid   = 'not-a-date';
    var dateInput = $('<input type="date">').val(invalid);
    var supported = dateInput.val() !== invalid;
    dateInput = undefined;
    return supported;
  }

  window.isoToLocalUsDate = isoToLocalUsDate;

})(window, jQuery);
