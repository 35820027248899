;(function(window, $, _) {

  Parsley.addMessages('en', {
    defaultMessage: "This value seems to be invalid.",
    type: {
      email:        'should be a valid email address',
      url:          'should be a valid url',
      number:       'should be a valid number',
      integer:      'should be a valid integer',
      digits:       'should be digits',
      alphanum:     'should be alphanumeric'
    },
    notblank:       'should not be blank',
    required:       'required',
    pattern:        'not valid',
    min:            'should be >= %s',
    max:            'should be <= %s.',
    range:          'should be between %s & %s',
    minlength:      'should have %s characters or more',
    maxlength:      'should have %s characters or fewer',
    length:         'should be between %s and %s characters long',
    mincheck:       'needs at least %s checked',
    maxcheck:       '<span class="hidden">should</span> select only %s',
    check:          'must select between %s and %s choices',
    equalto:        'does not match'
  });

  Parsley.setLocale('en');

  $.extend(window.Parsley.options, {
    excluded: 'input[type=button], input[type=submit], input[type=reset], input[type=hidden], .novalidation input, .novalidation select, .novalidation textarea, :hidden',
    trigger: 'blur change',
    errorClass: 'has-error',
    successClass: 'has-success',
    classHandler: function(ParsleyField) {
      return ParsleyField.$element.closest('.form-group');
    },
    errorsContainer: function(ParsleyField) {
      return ParsleyField.$element.closest('.form-group');
    },
    errorsWrapper: '<span class="help-block" />',
    errorTemplate: '<span></span>'
  });

  function feedback(ParsleyField) {

    if( ParsleyField.$element.is(':disabled') || ParsleyField.$element.prop('readonly') ) {
      return false;
    }

    var isValid = ParsleyField.isValid();
    var name = ParsleyField.$element.attr('name');
    var $group = ParsleyField.$element.closest('.form-group');
    var $feedback = $group.find('.form-control-feedback').first();
    var $status = $group.find('.form-control-status').first();

    $group.addClass('has-feedback');

    if(isValid === true) {
      $feedback.removeClass('glyphicon-remove').addClass('glyphicon-ok');
      $status.text('success');
    } else if(isValid === false) {
      $group.removeClass('has-not-failed');
      $feedback.removeClass('glyphicon-ok').addClass('glyphicon-remove');
      $status.text('error');
    } else {
      $group.addClass('has-not-failed');
      $feedback.removeClass('glyphicon-remove').addClass('glyphicon-ok');
      $status.text('success');
    }

  }

  function displayErrors(ParsleyForm) {

    var errors = {};
    var $firstError;

    $.each(ParsleyForm.fields, function(key, ParsleyField) {

      var group = ParsleyField.$element.data('parsleyGroup');
      var id = ParsleyField.$element.attr('id');
      var name = ParsleyField.$element.attr('name');
      var messages = ParsleyField.getErrorsMessages();
      var controlLabel;
      var label;

      if( $(ParsleyField.$element).is(':radio') || $(ParsleyField.$element).is(':checkbox') ) {
        var mult = $(ParsleyField.$element).data('parsleyMultiple');
        name = mult ? mult : name;
        controlLabel = $('label[for="' + name + '-input"]' ).first();
        controlLabel = controlLabel.length > 0 ? controlLabel : $('#' + name + '-description').first();
      } else {
        controlLabel = $('label[for="' + id + '"]' ).first();
      }

      label = controlLabel.length > 0 ? getLabelText(controlLabel) : name;

      if(!errors[group]) {
        errors[group] = [];
      }

      for(var i = 0; i < messages.length; i++) {
        if( !$firstError ) {
          $firstError = ParsleyField.$element;
        }

        errors[group].push({id: id, name: name, label: label.replace(' *', ''), message: decode(messages[i])});
      }

    });

    var errorAlert = _.template(
      '<ul>' +
        '<% _.forEach(errors, function(error) { %>' +
          '<li>' +
            '<a class="alert-link alert-link-normal" style="font-weight:normal" href="#<%- error.name %>-group">' +
              '<%- error.label %> <%- error.message %>' +
            '</a>' +
          '</li>' +
        '<% }); %>' +
      '</ul>'
    );

    if( $firstError.hasClass('selectize') ) {
      $firstError.next().find('.selectize-input input').click();
    }

    var scrollToAlert = true;

    $.each(errors, function(key, messages) {

      var $alert = $('#' + key + '--errors');

      if($alert.length > 0 && messages.length > 0) {

        $alert.html( errorAlert({errors: messages}) ).removeClass('hidden');

        if(scrollToAlert) {

          if($.fn.velocity) {
            $alert.velocity('scroll');
          } else {
            $('html,body').stop().animate({scrollTop: $alert.offset().top}, 'fast');
          }

          scrollToAlert = false;

        }

      } else {

        $alert.empty().addClass('hidden');

      }

    });

  }

  function hideErrors(ParsleyForm) {

    $(ParsleyForm.$element).find('.alert-danger:visible').each(function() {

      var $self = $(this);
      var fieldset = $self.data('errorsDescribed');

      if(ParsleyForm.isValid({group: fieldset})) {
        $self.empty().addClass('hidden');
      }

    });

  }

  function getLabelText(label) {
    if(!label) { return false; }
    var clone = label.clone();
    clone.find('.omit-from-summary').remove();
    var labelText = $.trim( clone.text() );
    clone.remove();
    return labelText;
  }

  function decode(value) {
    var render = $("<div />").html(value);
    return $("<textarea/>").html(render).text();
  }

  $(function() {

    // Workaround issue where valiation not firing on btn-group
    // radios on whole form validation

    var btnGroupRadios = $('.btn-group[data-toggle] :radio');

    if( btnGroupRadios.length > 0 ) {
      btnGroupRadios.change(function(e) {
        var $self = $(this);

        if( $self.data('parsleyMultiple') ) {
          $self.parsley().validate();
        }
      });
    }

  });

  window.Parsley.on('field:validated', feedback);

  window.Parsley.on('form:error', displayErrors);

  window.Parsley.on('form:success', hideErrors);

})(window, jQuery, _);
